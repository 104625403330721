function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import auth0 from 'auth0-js';
import startsWith from 'lodash/startsWith';
import Lockr from 'lockr';
import * as uuid from 'uuid';

function resolveUri(uri) {
  return startsWith(uri, '/') ? `${window.location.origin}${uri}` : uri;
}

export default class Auth0Client {
  constructor(_props) {
    _defineProperty(this, "isAuthenticated", () => {
      const currentAuth = Lockr.get(this.props.storageKey);
      return new Date().getTime() < ((currentAuth === null || currentAuth === void 0 ? void 0 : currentAuth.expiresAt) || 0);
    });

    _defineProperty(this, "setSession", authResult => {
      var _authResult$idTokenPa;

      const expiresAt = (authResult === null || authResult === void 0 ? void 0 : (_authResult$idTokenPa = authResult.idTokenPayload) === null || _authResult$idTokenPa === void 0 ? void 0 : _authResult$idTokenPa.exp) * 1000;
      Lockr.set(this.props.storageKey, { ...authResult,
        expiresAt
      });
    });

    _defineProperty(this, "login", props => {
      const nonce = uuid.v4();
      Lockr.set(this.props.nonceKey, nonce);
      this.client.authorize({
        nonce,
        state: nonce,
        ...props
      });
    });

    _defineProperty(this, "logout", props => {
      Lockr.rm(this.props.storageKey);
      Lockr.rm(this.props.nonceKey);
      this.client.logout({
        returnTo: resolveUri(this.props.logoutRedirectUri),
        clientID: this.props.clientID,
        ...props
      });
    });

    _defineProperty(this, "authenticate", () => new Promise((resolve, reject) => {
      if (this.isAuthenticated()) return resolve(Lockr.get(this.props.storageKey));
      const nonce = Lockr.get(this.props.nonceKey);
      return this.client.parseHash({
        hash: window.location.hash,
        nonce,
        state: nonce
      }, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else {
          reject(err || 'failed to authenticate');
        }
      });
    }));

    _defineProperty(this, "renewSession", async () => {
      await this.authenticate().catch(() => ({}));
      return new Promise((resolve, reject) => {
        const authSession = Lockr.get(this.props.storageKey);
        if (!authSession) return resolve(); // We resolve if a renewable session does not exist

        if (this.isAuthenticated()) return resolve(authSession);
        const nonce = Lockr.get(this.props.nonceKey);
        return this.client.checkSession({
          nonce
        }, (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(authResult);
          } else {
            Lockr.rm(this.props.storageKey);
            Lockr.rm(this.props.nonceKey);
            reject(err || 'failed to renew session');
          }
        });
      });
    });

    this.props = {
      redirectUri: '/authorize',
      responseType: 'token id_token',
      scope: 'openid',
      logoutRedirectUri: '/login',
      storageKey: 'auth',
      nonceKey: 'nonce',
      ..._props
    };
    const {
      domain,
      clientID,
      redirectUri,
      responseType,
      scope,
      audience
    } = this.props;
    this.client = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri: resolveUri(redirectUri),
      responseType,
      scope,
      audience
    });
  }

}